import React from "react";
 



function TabOne() {
    return ( 
        <div className="tabOne">
         <p>There are two tabs number 2 contains some projects made with html, css and js and number 3 contains projects made with react</p>
         </div>
    )
}
export default TabOne;